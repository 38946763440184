<template>
  <div id="app" class="min-h-screen bg-siteverydarkpurple w-full">
    
    <game-launcher-navbar 
      :mobile='this.mobile' :currentUser='getUser()'>
    </game-launcher-navbar>

    <router-view 
      :mobile='this.mobile' :currentUser='getUser()' 
      class="bg-sitedarkpurple p-6 ">
    </router-view>
   
    <game-launcher-footer></game-launcher-footer> 
    
  </div>
</template>

<script>
import Navbar from "./Navbar"
import Footer from "./components/Footer"
import { mapGetters, mapActions } from 'vuex'
import {userService} from './services/user.service'


export default {
  name: 'App',

  components: {
    'game-launcher-navbar':Navbar,
    'game-launcher-footer':Footer
  },

  data(){
    return {
      mobile: '',
      currentUser:''
    }
  },

  methods: {
    ...mapGetters([
        'getUser',
        'getNewToken',
    ]), 

    getPlatformName() {
      var userAgent = window.navigator.userAgent;
      var platform = window.navigator.platform;

      var platformsMap = [
        {
          platform: 'Windows',
          strings: ['Win16', 'Win32', 'Win64', 'Windows', 'WinCE']
        },
        {
          platform: 'Mac',
          strings: ['Macintosh', 'MacIntel', 'Intel Mac', 'MacPPC', 'Mac68K']
        },
        {
          platform: 'Android',
          strings: ['Android']
        },
        {
          platform: 'Linux',
          strings: ['Linux', 'X11']
        },
        {
          platform: 'iOS',
          strings: ['iPhone', 'iPad', 'iPod']
        }
      ];

      for (var i = 0; i < platformsMap.length; i++) {
        for (var j = 0; j < platformsMap[i].strings.length; j++) {
          if (userAgent.includes(platformsMap[i].strings[j])) {
            return platformsMap[i].platform;
          }
        }
        }
    },  

    detectiOS() {
      var ua = window.navigator.userAgent;
      // In iOS 13 Apple removed "iPad" from the iPad user-agent... So we need to check for touch points as well
      return (ua.toLowerCase().indexOf('macintosh') > -1 && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) || (ua.match(/ipad|iphone|ipod/i));
    },

    isMobile() {
      var platform = this.getPlatformName();
      if (platform == "iOS" || platform == "Android" || this.detectiOS())
        this.mobile = true
      else
        this.mobile = false

      if( window.innerWidth >= 640){
        this.mobile = false
      } 
      if( window.innerWidth < 640) {
        this.mobile = true
      }

      return this.mobile
    },
  
  },

  async mounted() {
    window.addEventListener('resize', this.isMobile)
    this.mobile = this.isMobile()

    this.currentUser = this.getUser()

    if (this.currentUser && !this.currentUser.id) {
      let getUser = await userService.getByEmail(this.currentUser.email)
      this.currentUser.id = getUser.id
      this.currentUser.havePassword = getUser.havePassword
    }

  },
  
  computed: {
    // isAdmin () {
    //   return this.currentUser && this.currentUser.role === Role.Admin
    // },
  },

  unmounted() {
    this.currentUser = ''
  },

}
</script>