import { handleResponse, requestOptions } from '@/helpers';
import axios from "axios";

export const userService = {
  getAll,
  // getById,
  getByEmail,
  setUser,
  deleteUser,
  updateUser,
  getRoles,
  forgotPassword,
  resetPassword
};

//Admin Page
async function getAll() {
  console.log("--- Get all users. ----")
  let response = await axios.request(requestOptions.get(`${process.env.VUE_APP_SERVER_API}/users`))
  return handleResponse(response)
}

// async function getById(id) {
//   console.log("--- Get user by id. ----")
//   let response = await axios.request(requestOptions.get(`${process.env.VUE_APP_SERVER_API}/users/${id}`))
//   return handleResponse(response)
// }

async function getByEmail(email) {
  console.log("--- Get user by email. ----")
  let response = await axios.request(requestOptions.get(`${process.env.VUE_APP_SERVER_API}/users/email/${email}`))
  return handleResponse(response)
}

// Admin Components - newUserModal
async function setUser(user){
  console.log("--- Set user. ----")
  console.log(`--- newUser: ${JSON.stringify(user)}`)
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/users/create`, {
    ...user
  }))
  return handleResponse(response)
}

async function deleteUser(id){
  console.log("--- Delete user. ----")
  let response = await axios.request(requestOptions.delete(`${process.env.VUE_APP_SERVER_API}/users/${id}`))
  return handleResponse(response)
}

async function updateUser(id, body){
  console.log("--- Update user. ----")
  // console.log(requestOptions.put(`${process.env.VUE_APP_SERVER_API}/users/update/${id}`,{blocked: blocked}))
  let response = await axios.request(requestOptions.put(`${process.env.VUE_APP_SERVER_API}/users/${id}`,{
    ...body
  }))
  return handleResponse(response)
}

async function getRoles() {
  console.log("--- Get roles. ----")
  let response = await axios.request(requestOptions.get(`${process.env.VUE_APP_SERVER_API}/roles`))
  return handleResponse(response)
}

async function forgotPassword(email) {
  console.log("--- Forgot password. ----")
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/users/resetPasswordRequest`,{
    email:email
  }))
  return handleResponse(response)
}

async function resetPassword(id, resetToken, password) {
  console.log("--- Reset password. ----")
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/users/resetPassword`,{
    id:id,
    resetToken: resetToken,
    password: password
  }))
  return handleResponse(response)
}
