import { authenticationService } from '../services'

export function handleResponse(response) {

  if(response.status >= 400){
    const error = (data && data.message) || response.statusText
    if ([401, 403].indexOf(response.status) !== -1) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      // or try refresh token logic
      authenticationService.logout()
    }
    return Promise.reject(error)
  }
  else{
    return response.data
  }
}