import { handleResponse, requestOptions } from '@/helpers'
import axios from "axios"

export const userCasinoGamesService = {
  getUserCasinos,
  getUserCasinoGames,
  saveUserGames
}

async function getUserCasinos(userId){
  console.log("--- Get casinos for user. ----")
  let response = await axios.request(requestOptions.get(`${process.env.VUE_APP_SERVER_API}/userCasinoGames/userId/${userId}/casinos`))
  return handleResponse(response)
}

// Admin Page
async function getUserCasinoGames(userId, casinoId) {
  console.log('--- Get user games for casino. ----')
  let response = await axios.request(requestOptions.get(`${process.env.VUE_APP_SERVER_API}/userCasinoGames/userId/${userId}/casinoId/${casinoId}/games`))
  return handleResponse(response)
}

//Admin Page
async function saveUserGames(userId, casinoId, games){
  console.log("--- Post new games for user. ----")
  // console.log(`CLIENT games: ${JSON.stringify(games)}`)
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/userCasinoGames/userId/${userId}/casinoId/${casinoId}/games`, {
    games
  }))
  return handleResponse(response)
}
