<template>
    <div class="flex-col justify-items-center items-center" >

    <div class="container mx-auto px-4 md:px-8">
      <div class="flex flex-col gap-8 items-center">
        <div class="flex-col bg-gray-700 p-4 w-1/10 rounded-l-2xl rounded-r-2xl" >
        <h1 class="text-3xl uppercase font-semibold mb-10 tracking-wider text-white w-fit mx-auto ">Reset Password</h1>

          <div v-if="!resetToken" class="w-full flex flex-col gap-y-5">
            <form class="flex flex-col items-center gap-y-5" @submit.prevent="sendEmail">
              <div class="form-field">
                <label class="w-1/4 md:w-1/3" id="email" for="emailInput">E-Mail Address</label>
                <input class="flex-1 dark:text-gray-900" 
                      id="emailInput" 
                      type="email"
                      v-model="email" required />
              </div>

              <div class="flex flex-col items-center text-base text-center">
                <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900" type="submit" >
                  Send password reset email
                </button>
              </div>

            </form>


            <div class="flex flex-col items-center text-base text-center">
              <router-link to="/" href="#" class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900">
              Back to Login
              </router-link>
            </div>
          </div>

          <div v-else>
            <form class="flex flex-col items-center gap-y-5" @submit.prevent="checkAndResetPassword">
              <div class="w-full flex flex-col items-center justify-between gap-5">
                <div class="w-full flex flex-col gap-y-5">
                  <div class="form-field">
                    <label class="w-1/4 md:w-1/3" id="password" for="passwordInput">Password</label>
                    <input class="flex-1 dark:text-gray-900" 
                          id="passwordInput" 
                          type="password"
                          placeholder="*********"
                          v-model="password" required />
                  </div>
                  <div class="form-field">
                    <label class="w-1/4 md:w-1/3" id="confirmPassword" for="confirmPasswordInput">Confirm Password</label>
                    <input class="flex-1 dark:text-gray-900" 
                          id="confirmPasswordInput" 
                          type="password"
                          placeholder="*********"
                          v-model="passwordVerify" required />
                  </div>
                  <div class="flex flex-col items-center text-base text-center">
                    <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900" type="submit" >Reset Password</button>
                  </div>
                </div>
                
            
              </div>

            </form>

          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { router } from '../router'
import {mapGetters} from "vuex"
import { Role } from '../helpers'
import {userService} from "../services"

export default {
  name: "Reset",

  data() {
    return { 
      email: "",
      password :"",
      passwordVerify: "",
      resetToken:""
    }
  },

  props:{
    mobile: Boolean, 
    currentUser: Object
  },
  
  methods: {
    ...mapGetters([
      'getUser'
    ]),
    async sendEmail(){
      const user = await userService.getByEmail(this.email)
      if(user){
        await userService.forgotPassword(this.email)
        this.$toast.info(`Verify your email`)
      }
      else {
        this.$toast.error(`Email not found`)
      }
      
    },

    async checkAndResetPassword(){
      if( this.password != this.passwordVerify){
        return this.$toast.info(`Passwords must match`)
         
      }
      if(this.password.length<8){
        return this.$toast.info(`Password must be at least 9 characters`)
         
      }

      const resetPassword = await userService.resetPassword(this.$route.query.id, this.$route.query.resetToken, this.password)
      
      if(resetPassword == true){
        this.$toast.success(`Password Reset Successfully..!`)
        router.push({ path:'/'})
      }
      else {
        return this.$toast.error(`Error to reset password..!`)
         
      }
     
    }
  },

  async mounted() {
    // let user = this.currentUser
    // if (user && !user.id) {
    //   let getUser = await userService.getByEmail(user.email)
    //   user.id = getUser.id
    //   user.havePassword = getUser.havePassword
    // }
    
    this.resetToken = this.$route.query.resetToken

  }
}
</script>
