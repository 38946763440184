<template>
  <form class="mt-8 space-y-6" action="#" method="POST">
    <!-- <input type="hidden" name="remember" value="true"/> -->
    <div class="rounded-md shadow-sm -space-y-px">
      <div>
        <label htmlFor="email-address" class="sr-only">Email address</label>
        <input id="email-address" name="email" type="email" autoComplete="email" required=""
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Email address"
              v-model="email"
        />
      </div>
      <div>
        <label htmlFor="password" class="sr-only">Password</label>
        <input id="password" name="password" type="password" autoComplete="current-password" required=""
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Password"
              v-model="password"
        />
      </div>
    </div>

    <div class="flex items-center justify-between">
      <router-link to="/reset" href="#" class="ml-2 block text-sm text-white">
        Forgot Password?
      </router-link>

      <div class="flex items-center">
        <input id="remember_me" name="remember_me" type="checkbox"
              class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"/>
        <label htmlFor="remember_me" class="ml-2 block text-sm text-white">
          Remember me
        </label>
      </div>
    </div>

    <div>
      <button @click.prevent="submitLogin" type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Sign in
      </button>
    </div>
          
  </form>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import {userService} from '../services/user.service'
import { Role } from '../helpers'

export default {
  name: "ManualAuth",

  data() {
    return {
      email:'',
      password: '',
    }
  },
  
  props:{
    mobile: Boolean, 
    currentUser: Object
  },

  methods: {
    ...mapGetters([
      'getUser'
    ]),
    ...mapActions([
      'doLogin', 
      'doLoginOauthUser'
    ]),
    async submitLogin(){
        this.doLogin({
        email: this.email,
        password: this.password
      })
    },
  },

  async mounted() {    
    let user = this.currentUser
    if (user && !user.id) {
      let getUserId = await userService.getByEmail(user.email)
      user.id = getUserId.id
    }

  }
}
</script>