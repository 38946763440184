<template>
  <nav class="w-full pt-2 flex items-center justify-between flex-wrap px-6 py-1.5 text-white space-x-6 relative">
    <!-- Logo -->
    <div class="flex items-center cursor-pointer" @click="goToHome()">
      <img class="fill-current h-6 w-auto mr-2" :src="logoImage" alt="Workflow">
      <div class="fill-current h-6 w-auto mr-2 text-lg" >Beta</div>
    </div>
    
    
    <div v-if="currentUser">
      <!-- MOBILE -->
      <div v-if="mobile==true">
        <!--HAMBURGUER BUTTON -->
        <div class="sm:hidden h-fit">
          <button @click="hamburguerMenuOpen = !hamburguerMenuOpen" 
                class="flex items-center p-2 text-teal-lighter border-teal-light">
            <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
            </svg>
          </button>
        </div>

        <!-- HAMBURGUER OPEN ITEMS -->
        <div v-if="hamburguerMenuOpen==true" class="absolute left-0 bg-siteverydarkpurple w-full z-50">
          <router-link to="/launcher" @click.native="hamburguerMenuOpen = !hamburguerMenuOpen"
                class="block hover:bg-sitepurple px-6 sm:px-4 py-2 text-sm rounded">
            Launcher
          </router-link>
          <router-link to="/admin" v-if="currentUser.role =='admin'" @click.native="hamburguerMenuOpen = !hamburguerMenuOpen"
                class="block hover:bg-sitepurple px-6 sm:px-4 py-2 text-sm rounded">
            Admin
          </router-link> 
          <router-link to="/profile"   @click.native="hamburguerMenuOpen = !hamburguerMenuOpen" 
                class="block hover:bg-sitepurple px-6 sm:px-4 py-2 text-sm rounded">
            Your Profile
          </router-link>
          <a @click.prevent="logout"  @click.native="hamburguerMenuOpen = !hamburguerMenuOpen"
                class="block hover:bg-sitepurple px-6 sm:px-4 py-2 text-sm rounded w-full text-left">
            Sign out
        </a>
        </div>

      </div>

      <!-- NOT MOBILE-->
      <div v-else class="w-full flex-grow sm:flex sm:items-center">
        <!-- LAUNCHER/ADMIN -->
        <router-link to="/launcher"
              class="block py-2 px-1 hover:bg-sitepurple sm:inline-block sm:mt-0 text-teal-lighter m-1 rounded">
          Launcher
        </router-link>
        <router-link v-if="currentUser.role =='admin' " to="/admin" 
              class="block py-2 px-1 hover:bg-sitepurple sm:inline-block sm:mt-0 text-teal-lighter m-1 rounded">
          Admin
        </router-link> 

        <div> 
        <!-- IMAGE PROFILE  BUTTON -->
        <button @click="profileMenuOpen = !profileMenuOpen" type="button" 
            class="flex items-center px-3 py-2 rounded text-teal-lighter border-teal-light">
          <!-- <img class="h-8 w-8 rounded-full" src="https://cataas.com/cat?width=50" alt=""> -->
          <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20">
							<path d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z"></path>
						</svg>

        </button>
        <!-- PROFILE MENU ITEMS-->
        <div v-if="profileMenuOpen==true" 
            class="origin-top-right sm:absolute sm:right-0 sm:mt-2 sm:w-48 sm:rounded-md sm:shadow-lg py-1 bg-siteverydarkpurple ring-1 ring-black ring-opacity-5 focus:outline-none" 
            role="menu" 
            aria-orientation="vertical" 
            aria-labelledby="user-menu-button" 
            tabindex="-1">
          <router-link to="/profile" @click.native="profileMenuOpen = !profileMenuOpen" 
                class="block hover:bg-sitepurple sm:px-4 py-2 text-sm rounded" 
                role="menuitem" 
                tabindex="-1" 
                id="user-menu-item-0">
            Your Profile
          </router-link>
          <a @click.prevent="logout" @click.native="profileMenuOpen = !profileMenuOpen"
                class="block hover:bg-sitepurple sm:px-4 py-2 text-sm rounded" 
                role="menuitem" 
                tabindex="-1" 
                id="user-menu-item-0">
            Sign out
        </a>   
        </div>
        </div>

        
      </div>

    </div>
  </nav>

</template>

<script>
import { router } from './router'
import { mapGetters, mapActions } from 'vuex'
import logoImage from "./assets/CRISTALTEC_ENTERTAINMENT_SIMPLE.png"

export default {
  name: 'Navbar',

  data () {
    return {
      logoImage: logoImage, 
      profileMenuOpen: false,
      hamburguerMenuOpen: false
    }
  },

  props:{
    mobile: Boolean, 
    currentUser: Object
  },

  methods: {
    closeProfileMenu(){
      this.profileMenu =!this.profileMenu
    },
    openMenu() {
    	this.showMenu = !this.showMenu

      if(this.showMenu == true){
        this.profileMenu = true
      } else {
        this.profileMenu = false
      }
    },
    showProfileButton() {
      if(this.mobile = false){
        this.showProfileMenuButton = true
        this.profileMenu = false
        this.showMenu = false
      } if(this.mobile = true){
        this.showProfileMenuButton = false
      }

    },
    goToHome() {
      if(this.$route.path != '/'){
        router.push('/')
      }
    },
    logout () {
      this.profileMenu = false
      this.doLogout()
      if(this.$route.path != '/'){
        router.push('/')
      }
      
    },
    ...mapGetters([
        'getUser',
        'getNewToken'
    ]),
    ...mapActions(['doLogout'])
  },

  unmounted() {
    this.hamburguerMenuOpen = false
    this.profileMenuOpen =  false
  },

}
</script>
<style scoped>
	.router-link-active {
		color: #42b983;
	}

	.router-link-exact-active {
		@apply bg-blue-600 text-white border-blue-600 rounded outline-none px-1 !important;
	}
</style>
