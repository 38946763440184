<template>
  <div>
    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-6xl">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 nrounded-t">
            <h3 class="text-3xl font-semibold text-sitepurple">Update Games List</h3>
            <button type="button" @click="closeModal">
              <svg class="w-8 h-8  text-red-500  hover:bg-red-500 hover:text-white font-bold text-sm rounded" 
                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
                clip-rule="evenodd"/>
              </svg>            
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <form class="w-full max-w-lg flex flex-wrap -mx-3 mb-6">

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-name">
                  Selected casino
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-name"
                  type="text"
                  v-model=" selectedCasino.name " required disabled/>
              </div>


              <div v-if="selectedCasino!=''" class="w-full px-3  max-h-72" >
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="games-input">
                  Games
                  <input mbsc-input id="my-input" data-dropdown="true" data-tags="true" />
                </label>
                <div class="divide-y-2 divide-black text-black w-full overflow-y-auto max-h-72">
                  <button
                        v-for="game in casinoGames" :key="game.id" 
                        class="w-full outline-none hover:bg-sitepurple hover:text-white focus:outline-none truncate"
                        :class="{'bg-blue-900 text-white': isGameAssigned(game) }"
                        @click.prevent="pickGame(game)">
                        {{ game.gameName }}
                  </button>
                </div>
              </div> 

            </form>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button class="inline-flex px-6 py-2 font-semibold rounded-lg shadow-lg text-white bg-blue-600 hover:bg-blue-900"
                @click="deleteSelectedGames">
              Delete Selected Games
            </button>
          </div>

        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import { casinoGamesService } from "../../services"
import * as _ from 'lodash'

export default {
  name: "update-games-list-modal",

  data() {
    return {
      casinoGames: [],
      selectedGames:[]
    }
  },

  props:['selectedCasino'],

  mounted() {
    this.getCasinoGames(this.selectedCasino.id)
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
    isGameAssigned(game){
      return this.selectedGames.some(selectedGame => _.isEqual(selectedGame, game))
    },
    pickGame(game){
      const foundGame = _.findIndex(this.selectedGames, game)
      console.log(`FOUND GAME AT ${foundGame}`)
      if(foundGame >= 0){
        this.selectedGames.splice(foundGame, 1)
      }else if(foundGame == -1){
        this.selectedGames.push(game)
      }
    },
    resetGames(){
      this.selectedGames = []
    },
    async getCasinoGames(casinoId){
      this.resetGames()
      let notOrderedCasinoGamesByCasinoId = await casinoGamesService.getCasinoGames(casinoId)
      this.casinoGames = _.orderBy(notOrderedCasinoGamesByCasinoId, 'name', 'asc')

      return this.casinoGames
      
    },
    async deleteSelectedGames() {
      if(this.selectedGames.length == 0){
        return this.$toast.warning(`Please, select games to delete!`)

      } 
      else {
        const deletedCasinoGames = await casinoGamesService.deleteCasinoGames(this.selectedCasino.id, this.selectedGames)
        if( deletedCasinoGames == "Casino games successfully deleted"){
          this.selectedGames = []
          this.getCasinoGames(this.selectedCasino.id)
          // this.getCasinos()
          // this.selectedCasino = null
          // this.casinoGames = []
          return this.$toast.success(`Games successfully deleted!`)
           
        } 
        else {
          return this.$toast.error(`Error to delete casino games`)
           
        }
        // this.$toast.show(`Hey! I'm here`)
        // this.$toast.error(`Error to added casino`)
        // this.$toast.warning(`Hey! I'm here`)
        // this.$toast.info(`Hey! I'm here`)
      }

    },
  },
}
</script>