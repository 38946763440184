import { createApp } from 'vue'
import App from './App.vue'
import '../public/style.css'
import { router } from './router'
import store from './store'
import Toasted from '@meforma/vue-toaster';
import interceptorsSetup from './helpers/interceptors'


const toastedConfig = {
  position: 'top',
  duration: 3000,
  pauseOnHover: true,
  useDefaultCss: true
}

createApp(App).use(store).use(router).use(Toasted, toastedConfig).provide('toast', createApp(App).config.globalProperties.$toast).mount('#app')
interceptorsSetup()