<template>
<div >
  <div class="container mx-auto px-4 md:px-8 text-center">

    <h1 class="text-3xl uppercase font-semibold tracking-wider w-full mb-10 text-white">Your Profile</h1>
    
    <div class="flex justify-center divide-x-2 md:w-4/5 mx-auto">
      <div class=" bg-gray-700 p-4 w-1/10  rounded-l-2xl rounded-r-2xl" >

        <form class="flex flex-col items-center gap-y-5" @submit.prevent="checkAndResetPassword">
          <div class="w-full flex flex-col items-center justify-between gap-5">
            <!-- USERNAME -->
            <div class="w-full flex flex-col gap-y-5">
              <div class="form-field">
                <label class="w-1/4 text-xs sm:text-sm md:w-1/3" id="name" for="nameInput">Username</label>
                <input class="flex-1 dark:text-gray-900" name="name" id="nameInput" 
                      v-model="currentUser.name" required disabled/>
              </div>
            </div>

            <!-- EMAIL -->
            <div class="w-full flex flex-col gap-y-5">
              <div class="form-field">
                <label class="w-1/4 text-xs sm:text-sm md:w-1/3" id="email" for="emailInput">Email</label>
                <input class="flex-1 dark:text-gray-900" name="email" id="emailInput" 
                      v-model="currentUser.email" required disabled/>
              </div>
            </div>

            <!-- ROLE -->
            <div class="w-full flex flex-col gap-y-5">
              <div class="form-field">
                <label class="w-1/4 text-xs sm:text-sm md:w-1/3" id="role" for="roleInput">Role</label>
                <input class="flex-1 dark:text-gray-900" name="role" id="roleInput" 
                      v-model="currentUser.role" required disabled/>
              </div>
            </div>

            <!-- PASSWORD -->
            <div class="w-full flex flex-col gap-y-5">
              <h1 class="text-xl uppercase font-semibold tracking-wider text-white w-fit mx-auto">Change Password</h1>
              
              <div v-if="currentUser.havePassword==true" class="form-field">
                <label class="w-1/4 text-xs sm:text-sm md:w-1/3" id="oldPassword" for="oldPasswordInput">Old Password</label>
                <input class="flex-1 dark:text-gray-900" 
                      id="oldPasswordInput" 
                      type="password"
                      placeholder="*********"
                      v-model="oldPassword" required />
              </div>

              <div class="form-field">
                <label class="w-1/4 text-xs sm:text-sm md:w-1/3" id="password" for="passwordInput">New Password</label>
                <input class="flex-1 dark:text-gray-900" 
                      id="passwordInput" 
                      type="password"
                      placeholder="*********"
                      v-model="newPassword" required />
              </div>
              <div class="form-field">
                <label class="w-1/4 text-xs sm:text-sm md:w-1/3" id="confirmPassword" for="confirmPasswordInput">Confirm Password</label>
                <input class="flex-1 dark:text-gray-900" 
                      id="confirmPasswordInput" 
                      type="password"
                      placeholder="*********"
                      v-model="passwordVerify" required />
              </div>
              <div class="flex flex-col items-center text-base text-center">
                <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900 text-white" type="submit" >
                  Change Password
                </button>
              </div>
            </div>
            
        
          </div>

        </form>
      </div>
    </div>
  </div>


</div>
</template>

<script>
import {mapGetters} from "vuex"
import {userService} from "../services"

export default {
  name: "Profile",

  data() {
    return { 
      oldPassword:"",
      newPassword :"",
      passwordVerify: "",
    }
  },

  props:{
    mobile: Boolean, 
    currentUser: Object
  },

  
  methods: {
    ...mapGetters([
      'getUser'
    ]),
    async checkAndResetPassword(){
      if(this.newPassword != this.passwordVerify){
        return this.$toast.info(`Passwords must match`)
      }
      if(this.newPassword.length<9){
        return this.$toast.info(`Password must be at least 9 characters`)
      }

      const updatedUser = await userService.updateUser(this.currentUser.id, { password: this.newPassword, oldPassword: this.oldPassword })
      if(updatedUser == 1){
        return this.$toast.success(`Password successfully updated..!`)
      }
      else if(typeof updatedUser == "string") {
        return this.$toast.error(updatedUser)
      }else {
        return this.$toast.error(`Error to update password`)
      }

    }
  },

  // async mounted() {
  //   let user = this.currentUser
  //   if (user && !user.id) {
  //     let getUser = await userService.getByEmail(user.email)
  //     user.id = getUser.id
  //     user.havePassword = getUser.havePassword
  //   }
    
  // },

  computed: {
    // havePassword() {
    //   if(this.getUser.password){
    //     console.log("antes computed " this.currrentUser.havePassword)
    //     return tthis.currrentUser.havePassword = true
    //   }
    // }
    
  },

}
</script>
