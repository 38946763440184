<template>
  <div v-on:click.ctrl="toggleSignIn" class="flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 text-white">
    
    <!-- WITH USER -->
    <div v-if="currentUser" class="flex-col justify-items-center items-center">
      <h1 class="flex flex-col items-center text-3xl uppercase font-semibold mb-10 tracking-wider">Home</h1>
      <p>You're logged in with Vue.js & JWT!!</p>
      <p>Your role is: <strong>{{currentUser.role}}</strong>.</p>
      <p>This page can be accessed by all authenticated users.</p>
      <p>Current user from secure api end point: </p> 
      <ul>
        <li>{{currentUser.name}} </li>
        <li>{{currentUser.email}}</li>
      </ul>
      
    </div>


    <!-- WITHOUT USER -->
    <div v-else class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-12 w-auto" :src="logoImage" alt="Workflow"/>
        <h2 class="mt-6 text-center text-3xl font-extrabold ">
          Sign in to your account
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          {{ ' ' }}
        </p>
      </div>

      <!-- LUDUSAUTH SIGN IN -->
      <div v-if="manualSignIn==false" class="w-fit mx-auto">
        <LudusAuth :backendEndpoint='getBackendEndpoint'></LudusAuth>
      </div>

      <!--MANUAL SIGN IN -->
      <div v-else  class="max-w-md w-full space-y-8">
        <ManualAuth :currentUser='getUser()'></ManualAuth>
      </div>
      
    </div>
  </div>
</template>

<script>
import logoImage from '../assets/CRISTALTEC_ENTERTAINMENT_SIMPLE.png'
import {mapActions, mapGetters} from "vuex";
import {userService} from '../services/user.service'
import { Role } from '../helpers'
import {LudusAuth} from "@ludus-npm/vue-auth"
import ManualAuth from '../components/ManualAuth'

export default {
  name: "Home",

  components: { LudusAuth, ManualAuth },

  props:{
    mobile: Boolean, 
    currentUser: Object
  },

  data() {
    return {
      manualSignIn: false,
      logoImage: logoImage
    }
  },
  
  methods: {
    ...mapGetters([
      'getUser'
    ]),

    toggleSignIn(){
      if(this.manualSignIn == false){
        this.manualSignIn = true
      }
      else if(this.manualSignIn == true){
        this.manualSignIn = false 
      }
      else if(this.manualSignIn == true && this.currentUser){
        this.manualSignIn = false
      }
      return this.manualSignIn
    },
  },

  computed: {
    getBackendEndpoint(){
      return process.env.VUE_APP_BACKEND_ENDPOINT || 'notworking'
    },
  },

  // async mounted() {    
  //   let user = this.currentUser
  //   if (user && !user.id) {
  //     let getUser = await userService.getByEmail(user.email)
  //     user.id = getUser.id
  //     user.havePassword = getUser.havePassword
  //   }
  // }
  
}
</script>
