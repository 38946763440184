<template>
  <div>
    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-6xl">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 nrounded-t">
            <h3 class="text-3xl font-semibold text-sitepurple">Settings</h3>
            <button type="button" @click="closeModal">
              <svg class="w-8 h-8  text-red-500  hover:bg-red-500 hover:text-white font-bold text-sm rounded" 
                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
                clip-rule="evenodd"/>
              </svg>            
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <form class="w-full max-w-lg flex flex-wrap -mx-3 mb-6">

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-theme">
                  Theme
                </label>
                <div class="relative">
                  <select class=" block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        name="grid-theme"
                        id="grid-theme"
                        v-model="selectedTheme"
                        required>
                    <option v-for="theme in themeOptions" :key="theme" :value="theme">
                      {{ theme }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" id="localServer" for="grid-localServer">
                  Local Server
                </label>
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-localServer"
                      type="text"
                      placeholder="Insert local server"
                      name="localServer"
                      v-model="localServer"/>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-qrCpde">
                  Show Qr Code
                </label>
                <div class="relative">
                  <select class=" block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        name="grid-qrCode"
                        id="grid-qrCode"
                        v-model="qrcode"
                        required>
                    <option :value="true">yes</option>
                    <option :value="false">no</option>
                  </select>
                </div>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-role">
                  Always Refresh Session Tokens
                </label>
                <div class="relative">
                  <select class=" block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        name="grid-role"
                        id="grid-role"
                        v-model="sessionTokens"
                        required>
                    <option :value="true">yes</option>
                    <option :value="false">no</option>
                  </select>
                </div>
              </div>

            </form>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b" >
            <!-- <button class="px-6 py-2 font-semibold rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900 text-white"
                  type="button"
                  @click="createUser">
              Submit
            </button> -->
          </div>

        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>



   <!-- <div class="w-screen h-screen overflow-hidden fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" 
       :class="[visible ? 'visible' : 'invisible']">
    <div class="relative w-full sm:w-3/4 lg:w-3/5 p-8 lg:p-12 mx-auto bg-gray-100 dark:bg-gray-800 dark:text-white shadow-xl rounded-xl">
      <button
          class="absolute hover:p-2 hover:text-blue-600 focus:outline-none p-3 transition-all duration-150"
          style="top: 1rem right: 1rem"
          @click="closeSettings">
        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"/>
        </svg>
      </button>

      <form class="flex flex-col items-center gap-y-5">
        <h1 class="text-3xl uppercase font-semibold mb-10 tracking-wider">Settings</h1>

        <div class="form-field">
          <label class="w-1/3" id="theme" for="themeInput">Theme</label>
          <select class="flex-1 dark:text-gray-900" name="theme" id="themeInput" v-model="selectedTheme">
            <option v-for="theme in themeOptions" :key="theme" :value="theme">{{ theme }}</option>
          </select>
        </div>

        <div class="form-field">
          <label class="w-1/3" id="localServer" for="localServerInput">Local Server</label>
          <input class="flex-1 dark:text-gray-900" type="text" id="localServerInput" name="localServer"
                 v-model="localServer">
        </div>

        <div class="form-field">
          <label class="w-1/3" id="qrcode" for="qrcodeInput">Show QR code</label>
          <select class="flex-1 dark:text-gray-900" name="theme" id="qrcodeInput" v-model="qrcode">
            <option :value="true">yes</option>
            <option :value="false">no</option>
          </select>
        </div>

        <div class="form-field">
          <label class="w-1/3" id="sessionTokens" for="sessionTokensInput">Always Refresh Session Tokens</label>
          <select class="flex-1 dark:text-gray-900" name="theme" id="sessionTokensInput" v-model="sessionTokens">
            <option :value="true">yes</option>
            <option :value="false">no</option>
          </select>
        </div>
      </form>

    </div>
  </div>  -->
  
</div>
</template>

<script>
export default {
  name: "settings",


  data() {
    return {
      themeOptions: ['light', 'dark', 'OS default'],
      selectedTheme: 'OS default',
      localServer: '',
      qrcode: true,
      sessionTokens: false
    }
  },

  watch: {
    selectedTheme(theme) {
      if (theme === 'OS default' && 'theme' in localStorage) {
        localStorage.removeItem('theme')
      } else {
        localStorage.theme = theme
      }

      this.updateTheme()
    },
    localServer(url) {
      if (url === '') {
        localStorage.removeItem('localServer')
      } else {
        localStorage.localServer = url
      }
      
      this.$emit('local-server-updated', url)
    },
    qrcode(show) {
      localStorage.showQrCode = show

      this.$emit('show-qr-code-updated', show)
    },
    sessionTokens(alwaysRefresh) {
      localStorage.alwaysRefreshSessionTokens = alwaysRefresh

      this.$emit('refresh-session-tokens-updated', alwaysRefresh)
    }
  },

  mounted() {
    if ('theme' in localStorage && this.themeOptions.includes(localStorage.theme)) {
      this.selectedTheme = localStorage.theme
    }
    if ('localServer' in localStorage) {
      this.localServer = localStorage.localServer
    }
    if ('showQrCode' in localStorage) {
      this.qrcode = JSON.parse(localStorage.showQrCode)
    }
    if ('alwaysRefreshSessionTokens' in localStorage) {
      this.sessionTokens = JSON.parse(localStorage.alwaysRefreshSessionTokens)
    }
  },

  methods: {
    updateTheme() {
      const htmlDomElement = document.getElementsByTagName("html")[0]
      /* eslint-disable no-unsafe-negation */
      if (localStorage.theme === 'dark' || (!'theme' in localStorage && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        htmlDomElement.classList = ['dark']
      } else if (localStorage.theme === 'light') {
        htmlDomElement.classList = ['light']
      }
    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
