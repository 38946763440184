import axios from 'axios'
import store from '../store'
import {router} from '../router'
import Vue from 'vue'
import { createToaster } from '@meforma/vue-toaster'

const toaster = createToaster()



export default function setup() {
  axios.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if(error.response.status == 400){
      console.log(error.response.data)
      return toaster.error(error.response.data.validation.body.message)
      
    }
    if (error.response.status !== 403 && (error.response.status !== 400 && !error.config.url.includes('/refresh'))) {
      //if (error.response.status !== 401 && (error.response.status !== 400 && !error.config.url.includes('/auth/refresh'))) {
      if (error.response.data.error) {
        // this.$toast.error(error.response.data.error)
        // console.log(error.response.data.error.message)
        toaster.error(error.response.data.error.message)

      }

      return new Promise((resolve, reject) => {
        console.log(error)
        reject(error)
      })
    }

    if (error.config.url.includes('/refresh') || error.config.url.includes('/users/authenticate') || error.response.data.error === 'invalid token') {
      if (!error.config.url.includes('/users/authenticate')) {
        console.log('LOGOUT')
        store.dispatch('doLogout')
        router.push({ name: 'Home' })
      }

      return new Promise((resolve, reject) => {
        reject(error)
      })
    }


    return store.dispatch('getNewToken', {
      refreshToken: store.getters.getRefreshToken,
      email: store.getters.getUserEmail
    })
      .then((token) => {
        // store.dispatch('doUpdateToken', token)
        const config = error.config
        config.headers['Authorization'] = 'Bearer ' + token


        return new Promise((resolve, reject) => {
          axios.request(config)
            .then(response => {
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      })
      .catch((error) => {
        Promise.reject(error)
      })

  })
}
