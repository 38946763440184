import store from '../store'

export const requestOptions = {
  get(url) {
    return {
      url,
      method: 'get',
      ...headers()
    }
  },
  post(url, body) {
    return {
      url,
      method: 'post',
      ...headers(),
      data: body
    }
  },
  patch(url, body) {
    return {
      url,
      method: 'patch',
      ...headers(),
      data: body
    }
  },
  put(url, body) {
    return {
      url,
      method: 'put',
      ...headers(),
      data: body
    }
  },
  delete(url) {
    return {
      url,
      method: 'delete',
      ...headers()
    }
  }
}

function headers() {
  const currentUser = store.getters.getUser
  const authHeader = currentUser && currentUser.accessToken ? { 'Authorization': 'Bearer ' + currentUser.accessToken } : {}
  return {
    headers: {
      ...authHeader,
      'Content-Type': 'application/json'
    }
  }
}