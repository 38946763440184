<template>
  <div>
    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-6xl">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 nrounded-t">
            <h3 class="text-3xl font-semibold text-sitepurple">Update User</h3>
            <button type="button" @click="closeModal">
              <svg class="w-8 h-8  text-red-500  hover:bg-red-500 hover:text-white font-bold text-sm rounded" 
                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
                clip-rule="evenodd"/>
              </svg>            
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <form class="w-full max-w-lg flex flex-wrap -mx-3 mb-6">

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-name">
                  Selected user to update
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-name"
                  type="text"
                  v-model=" selectedUser.name " required disabled/>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-name">
                  Update Name
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-name"
                  type="text"
                  placeholder="Insert new name"
                  v-model="newName"/>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-email">
                  Update Email
                </label>
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-email"
                  type="email"
                  placeholder="Insert new email"
                  v-model="newEmail"/>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-password">
                  Update Password
                </label>
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-password"
                      type="password"
                      placeholder="******************"
                      v-model="newPassword"/>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-role">
                  Role
                </label>
                <div class="relative">
                  <select class=" block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        name="grid-role"
                        id="grid-role"
                        v-model="selectedRole"
                        required>
                    <option v-for="role in roles" :key="role" :value="role">
                      {{ role }}
                    </option>
                  </select>
                </div>
              </div>

            </form>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button class="inline-flex px-6 py-2 font-semibold rounded-lg shadow-lg text-white bg-blue-600 hover:bg-blue-900"
                @click="updateUser">
              Update User
            </button>
          </div>

        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import { userService } from "../../services"

export default {
  name: "update-user-modal",

  data() {
    return {
      newName: this.selectedUser.name,
      newEmail: this.selectedUser.email,
      newPassword: this.selectedUser.password,
      roles: [],
      selectedRole: this.selectedUser.role,
    }
  },

  props:['selectedUser'],

  mounted() {
    this.getRoles()
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
    async getRoles() {
      this.roles = await userService.getRoles()
    },
    async updateUser(){
      // console.log("user to un/block: " +this.selectedUser.name+ " isBlocked: " +this.selectedUser.isBlocked)
      const updatedUser = await userService.updateUser(
        this.selectedUser.id, {
          email: this.newEmail,
          isBlocked: this.selectedUser.isBlocked,
          name: this.newName,
          password: this.newPassword,
          role: this.selectedRole
        }
      )
      
      if(updatedUser == 1){
        // this.selectedUser.isBlocked = !this.selectedUser.isBlocked
        // console.log(this.selectedUser.isBlocked)

        if(this.selectedUser == true){
          return this.$toast.success(`User successfully updated..!`)
        } 
      }
      else {
        return this.$toast.error(`Error to update user`)
      }

    },
  },
}
</script>