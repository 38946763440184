<template>
  <div>
    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-6xl">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 nrounded-t">
            <h3 class="text-3xl font-semibold text-sitepurple">New Casino</h3>
            <button type="button" @click="closeModal">
              <svg class="w-8 h-8  text-red-500  hover:bg-red-500 hover:text-white font-bold text-sm rounded" 
                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
                clip-rule="evenodd"/>
              </svg>            
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <form class="w-full max-w-lg flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-name">
                  Name
                </label>
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-name"
                      type="text"
                      placeholder="Insert new casino name"
                      v-model="newName"/>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-url">
                      Url
                </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-url"
                    type="text"
                    placeholder="Insert new url"
                    v-model="newUrl"/>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-token">
                      Access Token
                </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-token"
                    type="text"
                    placeholder="Insert new access token"
                    v-model="newToken"/>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-role">
                  Provider Type
                </label>
                <div class="relative">
                  <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white"
                        name="grid-role"
                        id="grid-role"
                        v-model="selectedProviderType"
                        required>
                    <option v-for="role in providerTypes" :key="role.id" :value="role">
                      {{ role.name }}
                    </option>
                  </select>
                </div>
                </div>
            </form>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b" >
            <button class="inline-flex px-6 py-2 font-semibold rounded-lg shadow-lg text-white bg-blue-600 hover:bg-blue-900"
                  @click="createCasino">
              Create Casino
            </button>
          </div>

        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import { casinoService, providerTypesService } from "../../services"

export default {
  name: "new-casino-modal",

  data() {
    return {
      newName: '',
      newUrl: '',
      newToken:'',
      providerTypes: [],
      selectedProviderType: null,
    }
  },

  mounted() {
    this.getProviderTypes()
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
    async getProviderTypes() {
      this.providerTypes = await providerTypesService.getProviderTypes()
    },
    async createCasino() {
      if(this.newName== '' || this.url== '' || this.newToken == '' || !this.selectedProviderType ){
        return this.$toast.warning(`Please fill all fileds`)
      }

      const newCasino = {
        name: this.newName,
        url: this.newUrl,
        accessToken: this.newToken,
        providerTypeId: this.selectedProviderType.id,
      }

      const setNewCasino = await casinoService.setCasino(newCasino)
      // console.log("...setNewCasino...")
      // console.log(setNewCasino)

      if(setNewCasino){
        this.newName=''
        this.newUrl=''
        this.newToken= ''
        this.selectedProviderType=null
        return this.$toast.success(`Casino successfully added..!`)
         
      } else {
        return this.$toast.error(`Error to added casino`)
         
      }
      
      
    },
  },
}
</script>