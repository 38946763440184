import { stringifyStyle } from '@vue/shared'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import {authenticationService} from "../services"

export default createStore({

  state: {
    user: null,
    isLoggedIn: false,
  },
  getters: {
    getUser: state => state.user,
    getToken: state => state.user.accessToken,
    getRefreshToken: state => state.user.refreshToken,
    getUserEmail: state => state.user.email,
    getIfUserHavePassword: state => state.user.havePassword,
    loggedIn: state => state.isLoggedIn,
  },
  mutations: {
    mutateUser: (state, user) => {
      const userData = {}

      userData.id = user.id
      userData.name = user.name
      userData.email = user.email
      userData.role = user.role
      userData.accessToken = user.accessToken || user.token
      userData.refreshToken = user.refreshToken
      userData.havePassword = user.havePassword

      state.user = userData
    },
    updateToken: (state, payload) => {
      state.user.accessToken = payload
    },
    logout: (state) => {
      state.user = null,
        state.isLoggedIn = false
    },
    clearError: (state) => {
      state.error = null
    },
    error: (state, payload) => {
      state.error = payload
    },
  },
  actions: {
    async doLogin({ commit }, userData) {
      let user = await authenticationService.login(userData.email, userData.password)
      commit('mutateUser', user)
    },
    doLoginOauthUser({ commit }, userData) {
      commit('mutateUser', userData)
    },
    doLogout({ commit }) {
      commit('logout')
    },
    async getNewToken({ commit }, user) {
      console.log(".....getNewToken....")
      const newAccessToken = await authenticationService.refreshToken(
        user.email,
        user.refreshToken)

      commit('updateToken', newAccessToken.accessToken)
      return newAccessToken.accessToken
    },

  },
  modules: {
  },
  plugins: [createPersistedState()]
})
