<template>
<div>
  <div class="container mx-auto md:px-8 text-white text-center">
    <new-user-modal v-if="showNewUserModal" @close="closeNewUserModal"></new-user-modal>
    <new-casino-modal v-if="showNewCasinoModal" @close="closeNewCasinoModal"></new-casino-modal>
    <update-casino-modal :selectedCasino=this.selectedCasino v-if="showUpdateCasinoModal" @close="closeUpdateCasinoModal"></update-casino-modal>
    <update-user-modal :selectedUser=this.selectedUser v-if="showUpdateUserModal" @close="closeUpdateUserModal"></update-user-modal> 
    <update-games-list-modal 
      :selectedCasino=this.selectedCasino 
      v-if="showGamesListModal" 
      @close="closeGamesListModal">
    </update-games-list-modal>    

    <h1 class="text-3xl uppercase font-semibold tracking-wider w-full mb-10">Admin</h1>

    <div class="flex justify-center divide-x-2 md:w-4/5 mx-auto"> <!--Vertical bars-->
      <!-- USER LIST -->
      <div class="bg-gray-700 p-4 flex-1 rounded-l-2xl">
        <div class="font-bold pb-2">User List</div>
         <div class="divide-y-2 divide-white w-full overflow-y-auto max-h-72"> <!--Horizontal bars -->
          
          <div v-for="user in users" :key="user.id" >
            <button class="w-full outline-none hover:bg-sitepurple focus:outline-none"
                  :class="{'bg-siteblue': selectedUser && user.id === selectedUser.id, 'text-gray-500': user.isBlocked}"
                  @click="pickUser(user)">
                  {{ user.name }}
            </button>
          </div>
        </div>
          
        <div class="flex flex-col items-center mt-5">
          <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900"
                @click="openNewUserModal">
                Create
          </button>
          <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900" 
                @click="deleteUser">
                Delete
          </button>
          <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900" 
                @click="openUpdateUserModal">
                Update
          </button>
          <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900" 
                @click="blockUser">
                Un/Block
          </button>
        </div>

      </div>

      <!-- CASINO LIST -->
      <div class=" bg-gray-700 p-4 flex-1">
        <div class="font-bold pb-2">Casino List</div>
        <div class="flex-col justify-center items-center divide-y-2 divide-white w-full">
          <div v-for="casino in casinos" :key="casino.id">
            <button class="w-full outline-none hover:bg-sitepurple focus:outline-none"
                  :class="{'bg-siteblue': selectedCasino && casino.id === selectedCasino.id}"
                  @click="pickCasino(casino)">
                  {{ casino.name }}
            </button>
          </div>
        </div>

        <div class="flex flex-col items-center mt-5">
          <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900"
                @click="openNewCasinoModal">
                Create
          </button>
          <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900" 
                @click="deleteCasino">
                Delete
          </button>
          <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900"
                @click="openUpdateCasinoModal">
                Update
          </button>
          <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900"
                @click="setCasinoGamesByCasinoId">
                Set games for casino
          </button>
        </div>

        <div class="flex justify-center items-center pt-6">
          <button class="px-6 py-2 font-semibold rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900 transition-all duration-150"
                @click="setAllCasinoGames">
                Set games for all casinos
          </button>
        </div>

        <!-- UPDATE ALL GAMES BUTTON-->
        <div v-if="casinoGames.length>0" class="flex justify-center items-center pt-6">
          <button class="px-6 py-2 font-semibold rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900 transition-all duration-150"
              @click="verifyUpdatesForAllGames">
              Verify updates for all games
          </button>
        </div>

      </div>

      <!-- GAMES LIST -->
      <div class="bg-gray-700 p-4 flex-1 rounded-r-2xl">
        <div class="font-bold pb-2"> Games List </div>
        <div class="divide-y-2 divide-white w-full overflow-y-auto max-h-72">
          <button
                v-for="game in casinoGames" :key="game.id" 
                class="w-full outline-none hover:bg-sitepurple focus:outline-none truncate"
                :class="{'bg-siteblue': isGameAssigned(game) }"
                @click.prevent="pickGame(game)">
                {{ game.gameName }}
          </button>
        </div>

        <div class="flex flex-col items-center mt-5">
          <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900"
                v-if="casinoGames.length > selectedGames.length"
                @click="selectAllCasinoGames"> 
                Select All
          </button>
          <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900"
                v-else-if="casinoGames.length === selectedGames.length && casinoGames.length>0"
                @click="clearAllCasinoGames"> 
                Clear Selection
          </button> 
          <button class="block my-1 px-1 py-1 rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900" 
                v-if="casinoGames.length>0"
                @click="openGamesListModal">
                Update games list
          </button>
        </div>

        <!-- SAVE BUTTON-->
        <div v-if="selectedUser && selectedCasino && casinoGames.length>0" class="flex justify-center items-center pt-6 mt-5">
          <button @click="saveUserState" class="px-6 py-2 font-semibold rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900 transition-all duration-150">
            Save
          </button>
        </div>

        <!-- 
           <div v-if="selectedUser && selectedCasino && casinoGames.length>0" class="flex justify-center items-center pt-6">
          <button class="px-6 py-2 font-semibold rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900 transition-all duration-150"
              @click="verifyUpdatesForAllGames">
              Verify updates for all games
          </button>
        </div>
          -->
        <div v-if="selectedUser && selectedCasino && casinoGames.length==0">
          <p>No games available</p>
        </div> 

      </div>

    </div>
    
  </div>
</div>
</template>

<script>
import {userService, casinoService, casinoGamesService, userCasinoGamesService} from "../services"
import * as _ from 'lodash'
import {newUserModal, newCasinoModal, updateCasinoModal, updateUserModal, updateGamesListModal} from "../components/admin/index.js"


export default {
  name: "Admin",

  components:{ newUserModal, newCasinoModal, updateCasinoModal, updateUserModal, updateGamesListModal},

  data() {
    return {
      users: [],
      selectedUser: null,
      casinos: [],
      selectedCasino: null,
      casinoGames: [],
      selectedGames: [],
      showNewUserModal: false,
      showNewCasinoModal: false,
      showUpdateCasinoModal: false,
      showUpdateUserModal: false,
      showGamesListModal: false
    }
  },

  props:{
    mobile: Boolean, 
    currentUser: Object
  },

  methods: {
    async getUsers() {
      let notOrderedUsers = await userService.getAll()
      this.users = _.orderBy(notOrderedUsers, 'name', 'asc')
      console.log(this.users)
    },
    async getCasinos(){
      this.casinos = await casinoService.getAll()
    },
    async setAllCasinoGames() {
      await casinoGamesService.setAllCasinoGames()
    },
    // OPEN AND CLOSE MODALS
    openNewUserModal() {
      this.showNewUserModal = true
    },
    openNewCasinoModal() {
      this.showNewCasinoModal = true
    },
    openUpdateCasinoModal() {
      if(!this.selectedCasino){
        return this.$toast.warning(`Please, select casino to update!`)
      } 
      this.showUpdateCasinoModal = true
    },
    openUpdateUserModal() {
      if(!this.selectedUser){
        return this.$toast.warning(`Please, select user to update!`)
      } 
      this.showUpdateUserModal = true
    },
    openGamesListModal(){
      this.showGamesListModal = true
    },
    closeNewUserModal() {
      this.showNewUserModal = false
      this.getUsers()
    },
    closeNewCasinoModal() {
      this.showNewCasinoModal = false
      this.getCasinos()
    },
    closeUpdateCasinoModal() {
      this.showUpdateCasinoModal = false
      this.getCasinos()
    },
    closeUpdateUserModal() {
      this.showUpdateUserModal = false
      this.getUsers()
    },
    closeGamesListModal() {
      this.showGamesListModal = false
      this.resetCasinos()
    },
    resetCasinos(){
      this.resetGames()
      this.selectedCasino = null
    },
    resetGames(){
      this.selectedGames = []
      this.casinoGames = []
    },
    pickUser(user){
      this.selectedUser = user
      if(this.selectedCasino){
        this.pickCasino(this.selectedCasino)
      }
    },
    pickCasino(casino){
      this.selectedCasino = casino
    },
    pickGame(game){
      const foundGame = _.findIndex(this.selectedGames, game)
      console.log(`FOUND GAME AT ${foundGame}`)
      if(foundGame >= 0){
        this.selectedGames.splice(foundGame, 1)
      }else if(foundGame == -1){
        this.selectedGames.push(game)
      }
    },
    isGameAssigned(game){
      return this.selectedGames.some(selectedGame => _.isEqual(selectedGame, game))
    },
    async getCasinoGames(casinoId){
      this.resetGames()
      let notOrderedCasinoGamesByCasinoId = await casinoGamesService.getCasinoGames(casinoId)
      this.casinoGames = _.orderBy(notOrderedCasinoGamesByCasinoId, 'gameName', 'asc')
      console.log("---- getCasinoGames for casinoId: "+ casinoId)
      console.log(this.casinoGames)
      return this.casinoGames
      
    },
    async getUserCasinoGames(userId, casinoId){
      this.selectedGames = await userCasinoGamesService.getUserCasinoGames(userId, casinoId)
    },
    selectAllCasinoGames(){
      this.selectedGames = this.casinoGames
    },
    clearAllCasinoGames(){
      this.selectedGames = []
    },
    async saveUserState(){
      userCasinoGamesService.saveUserGames(this.selectedUser.id, this.selectedCasino.id, this.selectedGames)
      await this.$toast.success(`Games saved for user..!`)
    },
    async verifyUpdatesForAllGames(){
      await casinoGamesService.updateAllGames()
    },
    async deleteUser() {
      if(!this.selectedUser){
        return this.$toast.info(`Please, select user to delete!`)
      } 
      else if(this.selectedUser.id == this.currentUser.id){
        return this.$toast.warning(`Cannot delete current user!`)
         
      }
      else {
        // console.log("username to delete: " + this.selectedUser.name)
        const deletedUser = await userService.deleteUser(this.selectedUser.id)

        if(deletedUser == "User successfully deleted"){
          this.getUsers()
          this.selectedUser = null
          return this.$toast.success(`User successfully deleted..!`)
           
        } 
        else {
          this.$toast.error(`Error to delete user`)
        }
        
      }
    },
    async blockUser(){
      if(!this.selectedUser){
        return this.$toast.info(`Please, select user to un/block!`)
      } 
      else if(this.selectedUser.id == this.currentUser.id){
        return this.$toast.warning(`Cannot block current user!`)
      }
      else {
        // console.log("user to un/block: " +this.selectedUser.name+ " isBlocked: " +this.selectedUser.isBlocked)
        const updatedUser = await userService.updateUser(this.selectedUser.id, {isBlocked: !this.selectedUser.isBlocked})
        
        if(updatedUser == 1){
          this.selectedUser.isBlocked = !this.selectedUser.isBlocked
          console.log("isBlocked: "+this.selectedUser.isBlocked)

          if(this.selectedUser.isBlocked == true){
            return this.$toast.success(`User successfully blocked!`)
          } 
          else{
            return this.$toast.success(`User successfully unblocked!`)
          }
        }
        else {
          return this.$toast.error(`Error to update user`)
        }
      }

    },
    async deleteCasino() {
      if(!this.selectedCasino){
        return this.$toast.warning(`Please, select casino to delete!`)
         
      } 
      else {
        // console.log("casinoName to delete: " + this.selectedCasino.name)
        const deletedCasino = await casinoService.deleteCasino(this.selectedCasino.id)
        if(deletedCasino == "Casino successfully deleted"){
          this.getCasinos()
          this.selectedCasino = null
          this.casinoGames = []
          return this.$toast.success(`Casino successfully deleted!`)
           
        } 
        else {
          return this.$toast.error(`Error to delete casino`)
           
        }
        // this.$toast.show(`Hey! I'm here`)
        // this.$toast.error(`Error to added casino`)
        // this.$toast.warning(`Hey! I'm here`)
        // this.$toast.info(`Hey! I'm here`)
      }
    },

    async setCasinoGamesByCasinoId() {
      if(!this.selectedCasino){
        return this.$toast.warning(`Please, select casino to set games!`)
         
      } 
      else {
        const casinoGames = await casinoGamesService.setCasinoGamesByCasinoId(this.selectedCasino.id)
        // console.log(casinoGames[0].games)
        if(casinoGames[0].games.length == 0){
          return this.$toast.error(`Don't create games for casino`)
        }
        if(casinoGames){
          this.getCasinoGames(this.selectedCasino.id)
          return this.$toast.success(`Casino games set successfully!`)
           
        }
        return this.$toast.error(`Error to set casino games`)
         
      }
    }

  },

  watch:{
    async selectedCasino(newcasino, oldcasino){
      await this.getCasinoGames(newcasino.id)
      if(this.selectedUser && this.selectedUser.id){
        this.getUserCasinoGames(this.selectedUser.id, newcasino.id)
      }
    },
    selectedUser(newUser, oldUser){
      if(this.selectedCasino && this.selectedCasino.id){
        this.getCasinoGames(this.selectedCasino.id)
        this.getUserCasinoGames(newUser.id, this.selectedCasino.id)
      }
    }
  },

  mounted() {
    this.getUsers()
    this.getCasinos()
  }
}
</script>

<style scoped>

</style>