<template>
  <footer id="footer" class="w-full pt-4 text-center text-white ">
    <!-- w-full text-center text-white p-4 pin-b -->
    © Copyright {{year}} | LudusCristaltec
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>

</style>