import { handleResponse, requestOptions } from '@/helpers';
import axios from "axios";

export const providerTypesService = {
  getProviderTypes
};

async function getProviderTypes() {
  console.log("--- Get providerTypes. ----")
  let response = await axios.request(requestOptions.get(`${process.env.VUE_APP_SERVER_API}/providerTypes`))
  return handleResponse(response)
}

