import { createRouter, createWebHistory } from 'vue-router'
import { Role } from '../helpers'
import Home from '../views/Home.vue'
import Reset from '../views/Reset.vue'
import Profile from '../views/Profile.vue'
import Admin from '../views/Admin.vue'
import Launcher from '../views/Launcher.vue'
import store from '../store'
import { OauthCallback } from "@ludus-npm/vue-auth"

import { providerTypesService } from '../services'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // component: () => import(/* webpackChunkName: "login" */ '../views/Home.vue')
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/oauth/callback',
    name: 'OauthCallback',
    component: OauthCallback,
    props: { backendEndpoint: process.env.VUE_APP_BACKEND_ENDPOINT }
  },
  {
    path: '/admin',
    meta: { authorize: [Role.Admin] },
    component: Admin
    // component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
  },
  {
    path: '/launcher',
    name: 'Launcher',
    component: Launcher,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "launcher" */ '../views/Launcher.vue')
  }
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // linkActiveClass: 'active-link',
  // linkExactActiveClass: 'exact-active-link',
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta
  const currentUser = store.getters.getUser

  if (authorize) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/', query: { returnUrl: to.path } })
    }

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUser.role)) {
      // role not authorised so redirect to home page
      return next({ path: '/' })
    }
  }

  if (currentUser && currentUser.role == Role.User && to.path == "/") {
    return next({ path: '/launcher' })
  }

  next()
})


