//import { BehaviorSubject } from 'rxjs'

//import config from 'config'
import { requestOptions } from '../helpers'
import { handleResponse } from '../helpers'
import { mapActions } from "vuex"
import axios from "axios"

//const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')))

let actions = { ...mapActions(['logoutUser']) }


export const authenticationService = {
  login,
  logout,
  refreshToken
}


async function login(email, password) {
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/users/authenticate`,
    { email, password }
  ))
  return handleResponse(response)
}

async function refreshToken(email, refreshToken) {
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/refresh`, { email, refreshToken }))
  return handleResponse(response)
}

function logout() {
  actions.logoutUser()
  console.log('logging out')
}